import {useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {useMediaQuery} from 'react-responsive'

export function useHomeLogic()
{
    const navigate = useNavigate()
    const isMobile = useMediaQuery({query: '(max-width: 768px)'})
    const location = useLocation()

    const containerRef = useRef<HTMLDivElement | null>(null)

    useEffect(() =>
    {
        if ('scrollRestoration' in window.history)
        {
            window.history.scrollRestoration = 'manual'
        }
    }, [])

    useEffect(() =>
    {
        if (containerRef.current)
        {
            containerRef.current.scrollTo(0, 0)
        }
        window.scrollTo(0, 0)
    }, [location])

    const handleOurStory = () => navigate('/pitch-deck')
    const handleOurApp = () => navigate('/vote-pilot')

    return {
        isMobile,
        containerRef,
        handleOurStory,
        handleOurApp
    }
}