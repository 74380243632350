import React, {useState, useEffect, useRef, useCallback} from 'react'
import {getDocument, GlobalWorkerOptions, PDFDocumentProxy} from 'pdfjs-dist'
import styled from 'styled-components'
import DirectDemocracyNavBar from './components/DirectDemocracyNavBar'

GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.mjs`

/**
 * Main page container with subtle background,
 * spaced to ensure the navbar doesn’t obstruct content.
 */
const PageWrapper = styled.div`
  min-height: 100vh;
  padding-top: 80px;
  background-color: #f9f9f9;
`

/**
 * Flex container with responsive settings for buttons and PDF canvas.
 * Its design promotes readability and easy interaction on all devices.
 */
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 80px);
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #333;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 20px;
  }
`

/**
 * Navigation button styled to be friendly and inviting,
 * with smooth transitions and minimal clutter.
 */
const NavButton = styled.button<{disabled: boolean}>`
  background-color: ${({disabled}) => (disabled ? '#ececec' : '#fff')};
  border: none;
  border-radius: 50%;
  cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'pointer')};
  width: 50px;
  height: 50px;
  margin: 10px;
  box-shadow: ${({disabled}) =>
        disabled ? 'none' : '0 4px 10px rgba(0, 0, 0, 0.08)'};
  transition: background-color 0.2s ease, transform 0.15s ease, box-shadow 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${({disabled}) => (disabled ? '#ececec' : '#fafafa')};
    transform: ${({disabled}) => (disabled ? 'none' : 'scale(1.05)')};
    box-shadow: ${({disabled}) =>
        disabled ? 'none' : '0 6px 12px rgba(0, 0, 0, 0.1)'};
  }

  &:active {
    transform: ${({disabled}) => (disabled ? 'none' : 'scale(0.95)')};
    box-shadow: ${({disabled}) =>
        disabled ? 'none' : '0 2px 4px rgba(0, 0, 0, 0.08)'};
  }

  svg {
    width: 18px;
    height: 18px;
    fill: #666;
  }

  @media (min-width: 768px) {
    width: 60px;
    height: 60px;
  }
`

/**
 * Canvas container with a soft shadow and rounded corners,
 * ensuring comfortable viewing and a pleasant aesthetic.
 */
const CanvasContainer = styled.div`
  position: relative;
  width: 90%;
  max-width: 800px;
  height: 70vh;
  margin: 10px 0;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  canvas {
    max-width: 100%;
    max-height: 100%;
    border-radius: 12px;
  }

  @media (min-width: 768px) {
    margin: 0 20px;
    height: 80vh;
  }
`

/**
 * Loader container that gracefully centers the spinner
 * while preserving a clean, minimal aesthetic.
 */
const LoaderWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`

/**
 * A subtle, circular spinner with a refined spin animation.
 */
const Spinner = styled.div`
  width: 48px;
  height: 48px;
  border: 4px solid #ddd;
  border-radius: 50%;
  border-top-color: #bbb;
  animation: spin 1s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`

interface PDFViewerProps
{
    pdfPath: string
}

const PDFViewer: React.FC<PDFViewerProps> = ({pdfPath}) =>
{
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768)
    const [pdf, setPdf] = useState<PDFDocumentProxy | null>(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [isLoading, setIsLoading] = useState(true) // Track loading state
    const canvasRef = useRef<HTMLCanvasElement>(null)

    useEffect(() =>
    {
        const handleResize = () =>
        {
            setIsMobile(window.innerWidth <= 768)
        }
        window.addEventListener('resize', handleResize)
        return () =>
        {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    /**
     * Renders a page onto the canvas, respecting the original aspect ratio
     * and allowing for higher resolution by accounting for device pixel ratio.
     */
    const renderPage = useCallback(async (pdfDoc: PDFDocumentProxy, pageNumber: number) =>
    {
        const page = await pdfDoc.getPage(pageNumber)
        const canvas = canvasRef.current
        if (!canvas) return

        const context = canvas.getContext('2d')
        if (!context) return

        const containerElement = canvas.parentElement
        if (!containerElement) return

        // Device Pixel Ratio for higher-res rendering on HiDPI screens
        const dpr = window.devicePixelRatio || 1

        // Container dimensions
        const containerWidth = containerElement.offsetWidth
        const containerHeight = containerElement.offsetHeight

        // Unscaled PDF page
        const unscaledViewport = page.getViewport({scale: 1})
        const {width: pdfWidth, height: pdfHeight} = unscaledViewport

        // Scale based on whichever dimension (width/height) is limiting
        const scaleX = containerWidth / pdfWidth
        const scaleY = containerHeight / pdfHeight
        const scale = Math.min(scaleX, scaleY) * dpr

        // Create a viewport with the chosen scale
        const viewport = page.getViewport({scale})

        // Canvas width/height for the drawing (at device pixel ratio)
        canvas.width = viewport.width
        canvas.height = viewport.height

        // Downscale the canvas in CSS to the actual container size
        canvas.style.width = `${viewport.width / dpr}px`
        canvas.style.height = `${viewport.height / dpr}px`

        await page.render({
            canvasContext: context,
            viewport
        }).promise
    }, [])

    // Load and display the first page of the PDF
    useEffect(() =>
    {
        const loadPdf = async () =>
        {
            try
            {
                setIsLoading(true)
                const pdfDoc = await getDocument(pdfPath).promise
                setPdf(pdfDoc)
                setTotalPages(pdfDoc.numPages)
                await renderPage(pdfDoc, 1)
            } catch (error)
            {
                console.error('Error loading PDF:', error)
            } finally
            {
                setIsLoading(false)
            }
        }
        loadPdf()
    }, [pdfPath, renderPage])

    // Navigate to the previous page
    const goToPreviousPage = useCallback(() =>
    {
        if (pdf && currentPage > 1)
        {
            const newPage = currentPage - 1
            setCurrentPage(newPage)
            renderPage(pdf, newPage)
        }
    }, [pdf, currentPage, renderPage])

    // Navigate to the next page
    const goToNextPage = useCallback(() =>
    {
        if (pdf && currentPage < totalPages)
        {
            const newPage = currentPage + 1
            setCurrentPage(newPage)
            renderPage(pdf, newPage)
        }
    }, [pdf, currentPage, totalPages, renderPage])

    // Re-render on orientation change (mobile/tablets)
    useEffect(() =>
    {
        const handleOrientationChange = () =>
        {
            if (pdf)
            {
                renderPage(pdf, currentPage)
            }
        }
        window.addEventListener('orientationchange', handleOrientationChange)
        return () =>
        {
            window.removeEventListener('orientationchange', handleOrientationChange)
        }
    }, [pdf, currentPage, renderPage])

    // Keyboard arrow navigation for desktop
    useEffect(() =>
    {
        const handleKeyDown = (event: KeyboardEvent) =>
        {
            if (event.key === 'ArrowLeft') goToPreviousPage()
            if (event.key === 'ArrowRight') goToNextPage()
        }
        window.addEventListener('keydown', handleKeyDown)
        return () =>
        {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [goToPreviousPage, goToNextPage])

    return (
        <PageWrapper>
            <DirectDemocracyNavBar
                isMobile={isMobile}
                backgroundColor="#ffffff"
            />

            <Container>
                <NavButton
                    onClick={goToPreviousPage}
                    disabled={currentPage === 1}
                    aria-label="Previous Page"
                >
                    <svg viewBox="0 0 20 20">
                        <path d="M12.707 14.707a1 1 0 01-1.414 0L5.586 9l5.707-5.707a1 1 0 011.414 1.414L8.414 9l4.293 4.293a1 1 0 010 1.414z" />
                    </svg>
                </NavButton>

                <CanvasContainer>
                    {/* Conditionally display the loader while the PDF is still loading */}
                    {isLoading && (
                        <LoaderWrapper>
                            <Spinner />
                        </LoaderWrapper>
                    )}
                    <canvas ref={canvasRef} />
                </CanvasContainer>

                <NavButton
                    onClick={goToNextPage}
                    disabled={currentPage === totalPages}
                    aria-label="Next Page"
                >
                    <svg viewBox="0 0 20 20">
                        <path d="M7.293 14.707a1 1 0 001.414 0L14.414 9l-5.707-5.707a1 1 0 00-1.414 1.414L11.586 9l-4.293 4.293a1 1 0 000 1.414z" />
                    </svg>
                </NavButton>
            </Container>
        </PageWrapper>
    )
}

export default PDFViewer