import {FC, CSSProperties, useEffect, useRef} from 'react'
import {FaApple} from 'react-icons/fa'
import {SiGoogleplay} from 'react-icons/si'
import {Link} from 'react-router-dom'

const OurApp: FC = () =>
{
    const styles = buildStyles()
    const containerRef = useRef<HTMLDivElement>(null)

    useEffect(() =>
    {
        if (containerRef.current)
        {
            const elements = containerRef.current.querySelectorAll('.fade-in-up')
            elements.forEach((el, i) =>
            {
                (el as HTMLElement).style.animationDelay = `${0.1 * i}s`
            })
        }
    }, [])

    return (
        <>
            <style>{getAnimationStyles()}</style>
            <div style={styles.outerWrapper}>
                <div ref={containerRef} style={styles.container}>
                    <h1 style={styles.title} className="fade-in-up">VotePilot</h1>
                    <p style={styles.text} className="fade-in-up">
                        Designed for Democracy
                    </p>
                    <div style={styles.buttonRow} className="fade-in-up">
                        <button
                            onClick={() => handleDownload('ios')}
                            aria-label="Download on the App Store"
                            style={styles.button}
                            onMouseEnter={(e) => handleHover(e, true)}
                            onMouseLeave={(e) => handleHover(e, false)}
                        >
                            <FaApple style={styles.icon} />
                            <span style={styles.buttonText}>App Store</span>
                        </button>
                        <button
                            onClick={() => handleDownload('android')}
                            aria-label="Get it on Google Play"
                            style={styles.button}
                            onMouseEnter={(e) => handleHover(e, true)}
                            onMouseLeave={(e) => handleHover(e, false)}
                        >
                            <SiGoogleplay style={styles.icon} />
                            <span style={styles.buttonText}>Google Play</span>
                        </button>
                    </div>

                    <div style={styles.contentBottomSpacer}></div>

                    <section style={styles.bottomSection} className="fade-in-up">
                        <Link
                            to="/our-story"
                            aria-label="Our Story"
                            style={styles.buttonMinor}
                            onMouseEnter={(e) => handleHomeHover(e, true)}
                            onMouseLeave={(e) => handleHomeHover(e, false)}
                        >
                            Our Story
                        </Link>
                    </section>
                </div>
            </div>
        </>
    )
}

export default OurApp

function buildStyles()
{
    const accentColor = '#1E3A8A'
    const textColor = '#333'
    const fontStack = '-apple-system, BlinkMacSystemFont, "San Francisco", "Helvetica Neue", "Segoe UI", sans-serif'
    const backgroundColor = '#FFFFFF'

    return {
        outerWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            backgroundColor: backgroundColor,
            padding: '60px 20px',
            overflow: 'hidden',
        } as CSSProperties,

        container: {
            maxWidth: '600px',
            textAlign: 'center',
            fontFamily: fontStack,
            color: textColor,
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
        } as CSSProperties,

        title: {
            margin: '0 0 20px 0',
            fontSize: '28px',
            fontWeight: 'bold',
            color: '#222',
            letterSpacing: '0.5px',
        } as CSSProperties,

        text: {
            fontSize: '18px',
            lineHeight: 1.6,
            color: textColor,
            marginBottom: '40px',
            fontWeight: 300,
        } as CSSProperties,

        buttonRow: {
            display: 'flex',
            gap: '20px',
            justifyContent: 'center',
        } as CSSProperties,

        contentBottomSpacer: {
            marginBottom: '60px',
        } as CSSProperties,

        button: {
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: "11px 22px",
            fontSize: "16px",
            backgroundColor: '#FFFFFF',
            color: accentColor,
            border: `1px solid ${accentColor}`,
            borderRadius: '18px',
            fontWeight: 400,
            fontFamily: fontStack,
            cursor: 'pointer',
            transition: 'background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease',
            boxShadow: '0 1px 3px rgba(0,0,0,0.08)',
            textDecoration: 'none',
            outline: 'none',
        } as CSSProperties,

        icon: {
            marginRight: '8px',
            color: accentColor,
            fontSize: '1.2em',
        } as CSSProperties,

        buttonText: {
            margin: 0,
            padding: 0,
        } as CSSProperties,

        bottomSection: {
            borderTop: '1px solid #F0F0F0',
            paddingTop: '40px',
            display: 'flex',
            justifyContent: 'center',
        } as CSSProperties,

        buttonMinor: {
            padding: '10px 22px',
            fontSize: '15px',
            backgroundColor: '#FFFFFF',
            color: accentColor,
            border: 'none',
            borderRadius: '20px',
            fontWeight: 300,
            letterSpacing: '0.25px',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0 1px 3px rgba(0,0,0,0.04)',
            textDecoration: 'none',
        } as CSSProperties,
    }
}

function getAnimationStyles()
{
    return `
    @keyframes fadeInUp {
      0% {
        opacity: 0;
        transform: translateY(10px) scale(0.98);
      }
      100% {
        opacity: 1;
        transform: translateY(0) scale(1);
      }
    }

    .fade-in-up {
      opacity: 0;
      animation: fadeInUp 0.6s cubic-bezier(0.4, 0.0, 0.2, 1) forwards;
    }
  `
}

function handleDownload(platform: 'ios' | 'android')
{
    const urls: Record<'ios' | 'android', string> = {
        ios: 'https://apps.apple.com/us/app/votepilot/id6670745730',
        android: 'https://play.google.com/store/apps/details?id=com.directdemocracy.vote_pilot&pcampaignid=web_share',
    }
    window.open(urls[platform], '_blank')
}

function handleHover(e: React.MouseEvent<HTMLButtonElement>, isHover: boolean)
{
    const button = e.currentTarget
    if (isHover)
    {
        button.style.backgroundColor = 'rgba(30,58,138,0.06)'
        button.style.boxShadow = '0 2px 6px rgba(0,0,0,0.08)'
        button.style.transform = 'scale(1.02)'
    } else
    {
        button.style.backgroundColor = '#FFFFFF'
        button.style.boxShadow = '0 1px 3px rgba(0,0,0,0.08)'
        button.style.transform = 'scale(1)'
    }
}

function handleHomeHover(e: React.MouseEvent<HTMLAnchorElement>, isHover: boolean)
{
    const link = e.currentTarget
    if (isHover)
    {
        link.style.backgroundColor = 'rgba(30,58,138,0.06)'
        link.style.boxShadow = '0 2px 6px rgba(0,0,0,0.08)'
        link.style.transform = 'scale(1.02)'
    } else
    {
        link.style.backgroundColor = '#FFFFFF'
        link.style.boxShadow = '0 1px 3px rgba(0,0,0,0.04)'
        link.style.transform = 'scale(1)'
    }
}