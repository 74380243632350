import React, {FC, CSSProperties, useEffect} from 'react'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import logo from "../images/Logo3.jpg"
import {useHomeLogic} from './useHomeLogic'

function useViewportHeightVariable()
{
  useEffect(() =>
  {
    const setVhProperty = () =>
    {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    setVhProperty()
    window.addEventListener('resize', setVhProperty)
    window.addEventListener('orientationchange', setVhProperty)
    return () =>
    {
      window.removeEventListener('resize', setVhProperty)
      window.removeEventListener('orientationchange', setVhProperty)
    }
  }, [])
}

export const Home: FC = () =>
{
  const {isMobile, containerRef, handleOurApp} = useHomeLogic()
  useViewportHeightVariable()
  const styles = buildStyles(isMobile)

  return (
    <>
      <style>{getAnimationStyles()}</style>
      <div style={styles.outerWrapper}>
        <div ref={containerRef} style={styles.container} className="fade-in-up">

          <div style={styles.topSection} className="fade-in-up">
            <div style={styles.logoWrapper}>
              <LazyLoadImage
                src={logo}
                alt="Logo"
                style={styles.logo}
                effect="blur"
                onError={(e) =>
                {
                  e.currentTarget.onerror = null
                  e.currentTarget.src = '/fallback-logo.png'
                }}
              />
            </div>
            <h1 style={styles.companyTitle}>
              VotePilot
            </h1>
          </div>

          <div style={styles.spacer} />

          <div style={styles.narrativeBlock} className="fade-in-up">
            <h2 style={styles.narrativeTitle}>Explore Smart Decisions </h2>
            <p style={styles.engineLine}>
              Crafted by Citizens
            </p>
          </div>

          <div style={styles.spacer} />

          <div style={styles.narrativeBlock} className="fade-in-up">
            <h2 style={styles.narrativeTitle}>Vote Together</h2>
            <p style={styles.engineLine}>Yes or No</p>
          </div>

          <div style={styles.spacer} />

          <div style={styles.buttonWrapper} className="fade-in-up">
            <button
              onClick={handleOurApp}
              aria-label="Our App"
              style={styles.button}
              onMouseEnter={(e) => handleHover(e, true)}
              onMouseLeave={(e) => handleHover(e, false)}
            >
              Shape the Future
            </button>
          </div>

          <div style={styles.largeBottomSpace} />
        </div>
      </div>
    </>
  )
}

export default Home

function buildStyles(isMobile: boolean)
{
  const accentColor = '#007aff'
  const baseTextColor = '#333'
  const backgroundColor = '#FFFFFF'
  const headingColor = '#222'
  const fontStack = `-apple-system, BlinkMacSystemFont, "San Francisco", "Helvetica Neue", "Segoe UI", sans-serif`

  const largeSpace = isMobile ? 60 : 100

  return {
    outerWrapper: {
      minHeight: 'calc(var(--vh, 1vh) * 100)',
      width: '100%',
      overflowY: 'auto',
      position: 'relative',
      backgroundColor: backgroundColor,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'stretch',
      boxSizing: 'border-box',
      flexDirection: 'column',
    } as CSSProperties,

    container: {
      position: 'relative',
      width: '100%',
      maxWidth: '800px',
      margin: '0 auto',
      fontFamily: fontStack,
      color: baseTextColor,
      textAlign: 'center',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: isMobile ? '80px' : '120px',
    } as CSSProperties,

    topSection: {
      boxSizing: 'border-box',
      flexShrink: 0,
      marginBottom: '0px',
    } as CSSProperties,

    logoWrapper: {
      marginBottom: isMobile ? '20px' : '32px',
    } as CSSProperties,

    logo: {
      width: isMobile ? '72px' : '90px',
      height: isMobile ? '72px' : '90px',
      objectFit: 'cover',
      borderRadius: '50%',
      boxShadow: '0 2px 5px rgba(0,0,0,0.05)',
    } as CSSProperties,

    companyTitle: {
      fontSize: isMobile ? '22px' : '26px',
      fontWeight: 500,
      margin: 0,
      letterSpacing: '0.5px',
      color: headingColor,
      lineHeight: 1.4,
    } as CSSProperties,

    spacer: {
      height: `${largeSpace}px`,
      width: '100%',
    } as CSSProperties,

    narrativeBlock: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: '70%',
      margin: '0 auto',
    } as CSSProperties,

    narrativeTitle: {
      fontSize: isMobile ? '20px' : '22px',
      lineHeight: 1.4,
      color: '#111',
      margin: '0 0 8px 0',
      fontWeight: 400,
    } as CSSProperties,

    engineLine: {
      fontSize: isMobile ? '13px' : '15px',
      lineHeight: 1.3,
      color: '#555',
      margin: 0,
      fontWeight: 300,
    } as CSSProperties,

    buttonWrapper: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    } as CSSProperties,

    button: {
      padding: isMobile ? "12px 24px" : "14px 32px",
      fontSize: isMobile ? "15px" : "16px",
      backgroundColor: '#ffffff',
      color: accentColor,
      border: `1px solid ${accentColor}`,
      borderRadius: '22px',
      fontWeight: 400,
      fontFamily: fontStack,
      cursor: 'pointer',
      transition: 'background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      textDecoration: 'none',
      letterSpacing: '0.2px',
      boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
    } as CSSProperties,

    largeBottomSpace: {
      height: `${largeSpace * 2}px`,
      width: '100%',
    } as CSSProperties,
  }
}

function getAnimationStyles()
{
  return `
    @keyframes fadeInUp {
      0% {
        opacity: 0;
        transform: translateY(10px) scale(0.98);
      }
      100% {
        opacity: 1;
        transform: translateY(0) scale(1);
      }
    }

    .fade-in-up {
      opacity: 0;
      animation: fadeInUp 0.6s cubic-bezier(0.4, 0.0, 0.2, 1) forwards;
    }
  `
}

function handleHover(e: React.MouseEvent<HTMLButtonElement>, isHover: boolean)
{
  const button = e.currentTarget
  if (isHover)
  {
    button.style.backgroundColor = 'rgba(0, 122, 255, 0.04)'
    button.style.boxShadow = '0 2px 6px rgba(0,0,0,0.08)'
    button.style.transform = 'scale(1.02)'
  } else
  {
    button.style.backgroundColor = '#FFFFFF'
    button.style.boxShadow = '0 1px 3px rgba(0,0,0,0.05)'
    button.style.transform = 'scale(1)'
  }
}