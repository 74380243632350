import {useMemo} from 'react'

/**
 * Reusable hook that transforms paragraphs to avoid orphan words at the end.
 *
 * @param rawParagraphs - An array of paragraph strings to be processed.
 * @param isMobile - A boolean indicating if the viewport is considered mobile.
 * @returns An array of transformed paragraph strings, with orphan words avoided.
 */
export function useAvoidOrphanWords(rawParagraphs: string[], isMobile: boolean): string[]
{
    // Step 2: Decide how many words must remain in the last line
    const minWordsAtEnd = isMobile ? 3 : 7

    /**
     * The unchanged orphan-word–avoidance function with a more descriptive name.
     */
    function avoidOrphanWords(text: string, minWords: number): string
    {
        const trimmed = text.trim()
        const words = trimmed.split(/\s+/)

        // If paragraph has fewer words total than minWords + 1, no change needed
        if (words.length < minWords + 1)
        {
            return text
        }

        const cutoffIndex = words.length - minWords
        const leadingWords = words.slice(0, cutoffIndex)
        const trailingWords = words.slice(cutoffIndex)

        // Rejoin the trailing words with non-breaking spaces
        const gluedTrailing = trailingWords.join('\u00A0')

        // Reassemble entire paragraph with normal spaces for leading portion
        return `${leadingWords.join(' ')} ${gluedTrailing}`
    }

    // Step 3: Use useMemo to perform the transformation only when inputs change
    const transformedParagraphs = useMemo(() =>
    {
        return rawParagraphs.map((paragraph) => avoidOrphanWords(paragraph, minWordsAtEnd))
    }, [rawParagraphs, minWordsAtEnd])

    return transformedParagraphs
}