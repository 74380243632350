import React from 'react'
import {Link} from 'react-router-dom'

interface DirectDemocracyNavBarProps
{
    isMobile: boolean
    backgroundColor: string
}

/**
 * A reusable navigation bar for Direct Democracy Corporation.
 */
export const DirectDemocracyNavBar: React.FC<DirectDemocracyNavBarProps> = ({
    isMobile,
    backgroundColor,
}) =>
{
    // Build navBar and navLink styles
    const navBarStyle: React.CSSProperties = {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: isMobile ? '15px' : '30px',
        boxSizing: 'border-box',
        borderBottom: '1px solid #EAEAEA',
        backgroundColor: backgroundColor,
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 9999,
    }

    const navLinkStyle: React.CSSProperties = {
        fontSize: isMobile ? '16px' : '18px',
        color: '#1E3A8A',
        textDecoration: 'none',
        padding: '6px 12px',
        borderRadius: '16px',
        transition: 'background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease',
        fontWeight: 400,
        letterSpacing: '0.2px',
    }

    const handleNavHover = (e: React.MouseEvent<HTMLAnchorElement>, isHover: boolean) =>
    {
        const link = e.currentTarget
        if (isHover)
        {
            link.style.backgroundColor = 'rgba(30,58,138,0.06)'
            link.style.boxShadow = '0 2px 6px rgba(0,0,0,0.08)'
            link.style.transform = 'scale(1.02)'
        } else
        {
            link.style.backgroundColor = '#FFFFFF'
            link.style.boxShadow = 'none'
            link.style.transform = 'scale(1)'
        }
    }

    return (
        <nav style={navBarStyle} className="fade-in-up">
            <Link
                to="/"
                style={navLinkStyle}
                onMouseEnter={(e) => handleNavHover(e, true)}
                onMouseLeave={(e) => handleNavHover(e, false)}
                aria-label="Go to Home"
            >
                VotePilot
            </Link>
        </nav>
    )
}

export default DirectDemocracyNavBar