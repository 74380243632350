import React, {useState, useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {Container, Row, Col} from 'reactstrap'
import DirectDemocracyNavBar from './DirectDemocracyNavBar'

/**
 * Jony Ive–inspired design, with a more spacious desktop layout.
 * - Mobile: White background, ~70px top margin, full width text.
 * - Desktop: Off-white background, a centered "card" up to ~900px wide.
 */
const AccountDeletionScreen: React.FC = () =>
{
    // Track mobile vs. desktop based on screen width
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768)

    useEffect(() =>
    {
        const handleResize = () => setIsMobile(window.innerWidth <= 768)
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    // Scroll to top on route change
    const location = useLocation()
    useEffect(() =>
    {
        window.scrollTo(0, 0)
    }, [location])

    // Outer wrapper: different background for mobile vs. desktop
    const outerWrapperStyle: React.CSSProperties = {
        width: '100%',
        minHeight: '100vh',
        backgroundColor: isMobile ? '#FFFFFF' : '#F5F5F5',
    }

    // Container for spacing:
    // Mobile: marginTop ~70px, minimal horizontal margin.
    // Desktop: marginTop ~140px, content centered with slight padding.
    const containerStyle: React.CSSProperties = isMobile
        ? {
            marginTop: '70px',
            padding: '0 1rem',
        }
        : {
            marginTop: '100px',
            display: 'flex',
            justifyContent: 'center',
            padding: '1rem',
        }

    // Desktop "card":
    // - width: 80% of the parent container
    // - maxWidth: 900px for nice reading width
    // - moderate padding so text isn’t too tight
    const desktopCardStyle: React.CSSProperties = {
        width: '80%',
        maxWidth: '900px',
        backgroundColor: '#FFFFFF',
        borderRadius: '10px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
        padding: '2rem',
        // Good typography defaults
        fontFamily: '-apple-system, BlinkMacSystemFont, "Helvetica Neue", Arial, sans-serif',
        lineHeight: 1.5,
    }

    return (
        <>
            {/* Optional fade-in styles (remove if not needed) */}
            <style>{fadeInStyles}</style>

            {/* Fixed navbar at the top, unchanged */}
            <DirectDemocracyNavBar
                isMobile={isMobile}
                backgroundColor="#FFFFFF"
            />

            {/* Outer background wrapper */}
            <div style={outerWrapperStyle} className="fade-in-up">
                <div style={containerStyle}>
                    {isMobile ? (
                        // MOBILE: no "card," full-width text
                        <MobileContent />
                    ) : (
                        // DESKTOP: center a wider "card," up to 900px
                        <div style={desktopCardStyle}>
                            <DesktopContent />
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default AccountDeletionScreen

/**
 * MOBILE content:
 * - No box or card, no extra shadow.
 * - Light horizontal padding to keep text off screen edge.
 */
function MobileContent()
{
    return (
        <Container style={{padding: 0}}>
            <Row className="justify-content-center">
                <Col xs="12" md="8" lg="6">
                    <div style={{
                        ...styles.content,
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        padding: '1rem',
                    }}>
                        <h2 style={styles.sectionTitle}>Account Deletion Request</h2>
                        <p style={styles.paragraph}>
                            If you would like to delete your account and all associated data, please contact us
                            using the email address that was used to create your account.
                        </p>
                        <p style={styles.paragraph}>
                            Once we receive your request, we will delete all your account information and
                            related data from our servers.
                        </p>
                        <p style={styles.paragraph}>
                            Please contact us at:{' '}
                            <a href="mailto:contact@directdemocracy.global" style={styles.hyperlink}>
                                contact@directdemocracy.global
                            </a>
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

/**
 * DESKTOP content:
 * - The actual "card" styling is in `desktopCardStyle`,
 *   so we neutralize background/boxShadow here.
 */
function DesktopContent()
{
    return (
        <Container style={{padding: 0}}>
            <Row className="justify-content-center">
                <Col xs="12" md="8" lg="12">
                    <div style={{
                        ...styles.content,
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        padding: 0, // rely on desktopCardStyle's own padding
                    }}>
                        <h2 style={styles.sectionTitle}>Account Deletion Request</h2>
                        <p style={styles.paragraph}>
                            If you would like to delete your account and all associated data, please contact us
                            using the email address that was used to create your account.
                        </p>
                        <p style={styles.paragraph}>
                            Once we receive your request, we will delete all your account information and
                            related data from our servers.
                        </p>
                        <p style={styles.paragraph}>
                            Please contact us at:{' '}
                            <a href="mailto:contact@directdemocracy.global" style={styles.hyperlink}>
                                contact@directdemocracy.global
                            </a>
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

/**
 * Gentle fade-in. Remove if not desired.
 */
const fadeInStyles = `
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(15px) scale(0.98);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
.fade-in-up {
  opacity: 0;
  animation: fadeInUp 0.5s cubic-bezier(0.4, 0.0, 0.2, 1) forwards;
}
`

/** Original snippet’s styles, lightly adapted. */
const styles: {[key: string]: React.CSSProperties} = {
    content: {
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        padding: '2rem',
    },
    sectionTitle: {
        fontSize: '1.5rem',
        fontWeight: 700,
        marginBottom: '1rem',
        color: '#1C1C1E',
    },
    paragraph: {
        fontSize: '1.125rem',
        color: '#3A3A3C',
        lineHeight: '1.5',
    },
    hyperlink: {
        color: '#007AFF',
        textDecoration: 'underline',
    },
}