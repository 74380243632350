import React, {useState, useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import DirectDemocracyNavBar from './DirectDemocracyNavBar'

export const PitchDeck: React.FC = () =>
{
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768)

  useEffect(() =>
  {
    const handleResize = () => setIsMobile(window.innerWidth <= 768)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const location = useLocation()

  useEffect(() =>
  {
    window.scrollTo(0, 0)
  }, [location])

  const styles = buildStyles(isMobile)

  const slides = [
    {
      title: 'Our Defining Cause',
      lines: [
        'Waning trust.',
        'Distant institutions.',
        'Unheard voices.'
      ]
    },
    {
      title: 'Our Vision',
      lines: [
        'A place where everyone is heard.',
        'Decisions shaped by understanding.',
        'A more honest, direct democracy.'
      ]
    },
    {
      title: 'VotePilot',
      lines: [
        'Complex ideas distilled with elegance.',
        'Strategy through the Vision Engine.',
        'Insights by the Wisdom Engine.'
      ]
    },
    {
      title: 'Opportunity',
      lines: [
        'From communities to global networks.',
        'A universal need for clarity.',
        'Designed to scale with trust.'
      ]
    },
    {
      title: 'Early Signals',
      lines: [
        'Enthusiastic early adopters.',
        'Authentic community trials.',
        'Validation before profit.'
      ]
    },
    {
      title: 'Our Team',
      lines: [
        'Technologists, advocates, scholars.',
        'Committed to human-centered governance.',
        'Driven by purpose and craft.'
      ]
    },
    {
      title: 'Growth',
      lines: [
        'Earn trust, refine, expand.',
        'Then: Thoughtful revenue models.',
        'Meaningful value over haste.'
      ]
    },
    {
      title: 'Our Future',
      lines: [
        'More voices.',
        'Better decisions.',
        'Democracy, realized at scale.'
      ]
    }
  ]

  return (
    <>
      <style>{getAnimationStyles()}</style>

      <DirectDemocracyNavBar
        isMobile={isMobile}
        backgroundColor={styles.outerWrapper.backgroundColor as string}
      />

      <div style={styles.outerWrapper}>
        <div style={styles.container} className="fade-in-up">
          <div style={styles.slidesWrapper}>
            {slides.map((slide, index) => (
              <section key={index} style={styles.slide}>
                <h2 style={styles.slideTitle}>{slide.title}</h2>
                {slide.lines.length > 0 && (
                  <div style={styles.slideLines}>
                    {slide.lines.map((line, i) => (
                      <p key={i} style={styles.slideLine}>
                        {line}
                      </p>
                    ))}
                  </div>
                )}
              </section>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default PitchDeck

function getAnimationStyles()
{
  return `
    @keyframes fadeInUp {
      0% {
        opacity: 0;
        transform: translateY(15px) scale(0.98);
      }
      100% {
        opacity: 1;
        transform: translateY(0) scale(1);
      }
    }

    .fade-in-up {
      opacity: 0;
      animation: fadeInUp 0.6s cubic-bezier(0.4, 0.0, 0.2, 1) forwards;
    }
  `
}

function buildStyles(isMobile: boolean)
{
  const fontStack =
    '-apple-system, BlinkMacSystemFont, "San Francisco", "Helvetica Neue", "Segoe UI", sans-serif'
  const headingColor = '#111'
  const baseTextColor = '#333'
  const backgroundColor = '#FFFFFF'
  const containerPadding = isMobile ? '100px 20px 60px 20px' : '120px 20px 60px 20px'

  return {
    outerWrapper: {
      backgroundColor: backgroundColor,
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column' as 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      boxSizing: 'border-box',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      fontFamily: fontStack,
    } as React.CSSProperties,


    container: {
      maxWidth: '800px',
      width: '100%',
      margin: '0 auto',
      padding: containerPadding,
      color: baseTextColor,
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column' as 'column',
      gap: isMobile ? '40px' : '60px',
      textAlign: 'center' as 'center',
      fontFamily: fontStack,
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
    } as React.CSSProperties,

    slidesWrapper: {
      display: 'flex',
      flexDirection: 'column' as 'column',
      gap: isMobile ? '50px' : '80px',
      marginTop: '60px',
    } as React.CSSProperties,

    slide: {
      display: 'flex',
      flexDirection: 'column' as 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 20px',
      minHeight: '60vh',
    } as React.CSSProperties,

    slideTitle: {
      fontSize: isMobile ? '24px' : '32px',
      fontWeight: 500,
      color: headingColor,
      margin: '0 0 20px',
      letterSpacing: '0.5px',
      lineHeight: 1.4,
    } as React.CSSProperties,

    slideLines: {
      display: 'flex',
      flexDirection: 'column' as 'column',
      gap: '10px',
      maxWidth: '600px',
    } as React.CSSProperties,

    slideLine: {
      fontSize: isMobile ? '16px' : '18px',
      lineHeight: 1.5,
      color: baseTextColor,
      margin: 0,
      fontWeight: 300,
      letterSpacing: '0.2px',
    } as React.CSSProperties,
  }
}