import React, {useState, useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import DirectDemocracyNavBar from './DirectDemocracyNavBar'

// ─────────────────────────────────────────────────────────────────────────────
// 1) The widont-like helper function, unchanged except for a descriptive name
//    and minor doc improvements.
// ─────────────────────────────────────────────────────────────────────────────
function avoidOrphanWords(text: string, minWordsAtEnd: number): string
{
    /*
      Avoid having fewer than minWordsAtEnd words in the last line by merging them
      with the preceding words via non-breaking spaces.
  
      Example:
        - If minWordsAtEnd=3, we do not want the last line to have fewer than 3 words.
        - "Hello there" => no changes (since it's only 2 words total).
        - "Lorem ipsum dolor sit amet" => merges last 3 words with non-breaking spaces:
           "Lorem ipsum dolor sit\u00A0amet".
    */
    const trimmed = text.trim()
    const words = trimmed.split(/\s+/)

    // If paragraph has fewer words total than minWordsAtEnd + 1, no change needed
    if (words.length < minWordsAtEnd + 1)
    {
        return text
    }

    const cutoffIndex = words.length - minWordsAtEnd
    const leadingWords = words.slice(0, cutoffIndex)
    const trailingWords = words.slice(cutoffIndex)

    // Rejoin the trailing words with non-breaking spaces
    const gluedTrailing = trailingWords.join('\u00A0')

    // Reassemble entire paragraph with normal spaces for leading portion
    return `${leadingWords.join(' ')} ${gluedTrailing}`
}

// ─────────────────────────────────────────────────────────────────────────────
// 2) Main Component
// ─────────────────────────────────────────────────────────────────────────────
const OurStory: React.FC = () =>
{
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768)

    useEffect(() =>
    {
        const handleResize = () => setIsMobile(window.innerWidth <= 768)
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const location = useLocation()
    useEffect(() =>
    {
        window.scrollTo(0, 0)
    }, [location])

    // 3) Your paragraphs. We won't alter them directly; we'll transform them via avoidOrphanWords.
    const rawParagraphs = [
        `They said citizens can't make good decisions without experts holding their hand. I answered by building a platform that empowers anyone to craft a decision with the rigor of a seasoned strategist and understand its strengths and weaknesses as clearly as any scholar.`,
    ]

    // 4) Determine how many words must be in the last line based on the viewport size
    const minWordsAtEnd = isMobile ? 5 : 7

    // 5) Transform paragraphs to avoid orphan words
    const paragraphs = rawParagraphs.map((p) => avoidOrphanWords(p, minWordsAtEnd))

    // 6) Get the styles and render
    const styles = buildStyles(isMobile)

    return (
        <>
            <style>{getAnimationStyles()}</style>

            <DirectDemocracyNavBar
                isMobile={isMobile}
                backgroundColor={styles.outerWrapper.backgroundColor as string}
            />

            <div style={styles.outerWrapper}>
                <div style={styles.container} className="fade-in-up">
                    <h2 style={styles.pageTitle}>Our Story</h2>

                    <div style={styles.storyWrapper}>
                        {paragraphs.map((para, idx) => (
                            <p key={idx} style={styles.founderMessage}>
                                {para}
                            </p>
                        ))}
                        <p style={styles.signature}>— Robert Karapetyan, Founder</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OurStory

// ─────────────────────────────────────────────────────────────────────────────
// The rest of your helper functions remain the same.
// ─────────────────────────────────────────────────────────────────────────────
function getAnimationStyles()
{
    return `
    @keyframes fadeInUp {
      0% {
        opacity: 0;
        transform: translateY(15px) scale(0.98);
      }
      100% {
        opacity: 1;
        transform: translateY(0) scale(1);
      }
    }
    .fade-in-up {
      opacity: 0;
      animation: fadeInUp 0.6s cubic-bezier(0.4, 0.0, 0.2, 1) forwards;
    }
  `
}

function buildStyles(isMobile: boolean): {[key: string]: React.CSSProperties}
{
    const fontFamily = `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif`

    return {
        outerWrapper: {
            backgroundColor: '#FFFFFF',
            minHeight: '100vh',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            boxSizing: 'border-box',
            overflowX: 'hidden',
            fontFamily,
        },
        container: {
            maxWidth: '800px',
            width: '100%',
            margin: '0 auto',
            padding: isMobile ? '100px 20px' : '140px 20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '40px',
            boxSizing: 'border-box',
            color: '#333333',
            textAlign: 'center',
            WebkitFontSmoothing: 'antialiased',
            MozOsxFontSmoothing: 'grayscale',
        },
        pageTitle: {
            fontSize: isMobile ? '28px' : '36px',
            fontWeight: 700,
            margin: 0,
            color: '#111111',
            letterSpacing: '0.6px',
            textTransform: 'none',
        },
        storyWrapper: {
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
        },
        founderMessage: {
            fontSize: isMobile ? '16px' : '18px',
            lineHeight: 1.7,
            whiteSpace: 'normal',
            margin: 0,
            fontWeight: 300,
            letterSpacing: '0.3px',
        },
        signature: {
            fontSize: isMobile ? '16px' : '18px',
            fontWeight: 400,
            marginTop: '20px',
            color: '#111111',
        },
    }
}