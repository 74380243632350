import React, {useState, useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import DirectDemocracyNavBar from './DirectDemocracyNavBar'
import {DirectDemocracyFooter} from './DirectDemocracyFooter'
import {useAvoidOrphanWords} from './useAvoidOrphanWords'

function getAnimationStyles()
{
    return `
    @keyframes fadeInUp {
      0% {
        opacity: 0;
        transform: translateY(15px) scale(0.98);
      }
      100% {
        opacity: 1;
        transform: translateY(0) scale(1);
      }
    }
    .fade-in-up {
      opacity: 0;
      animation: fadeInUp 0.6s cubic-bezier(0.4, 0.0, 0.2, 1) forwards;
    }
  `
}

function buildStyles(isMobile: boolean): {[key: string]: React.CSSProperties}
{
    const fontFamily = `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif`

    return {
        outerWrapper: {
            backgroundColor: '#FFFFFF',
            minHeight: '100vh',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            boxSizing: 'border-box',
            overflowX: 'hidden',
            fontFamily,
            // Ensures content isn't hidden behind the fixed footer
            paddingBottom: isMobile ? '60px' : '80px',
        },
        container: {
            maxWidth: '800px',
            width: '100%',
            margin: '0 auto',
            padding: isMobile ? '100px 20px' : '140px 20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '40px',
            boxSizing: 'border-box',
            color: '#333333',
            textAlign: 'center',
            WebkitFontSmoothing: 'antialiased',
            MozOsxFontSmoothing: 'grayscale',
        },
        pageTitle: {
            fontSize: isMobile ? '28px' : '36px',
            fontWeight: 700,
            margin: 0,
            color: '#111111',
            letterSpacing: '0.6px',
            textTransform: 'none',
        },
        storyWrapper: {
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
        },
        founderMessage: {
            fontSize: isMobile ? '16px' : '18px',
            lineHeight: 1.7,
            whiteSpace: 'normal',
            margin: 0,
            fontWeight: 300,
            letterSpacing: '0.3px',
        },
        signature: {
            fontSize: isMobile ? '16px' : '18px',
            fontWeight: 400,
            marginTop: '20px',
            color: '#111111',
        },
    }
}

export const Execution: React.FC = () =>
{
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768)

    useEffect(() =>
    {
        const handleResize = () => setIsMobile(window.innerWidth <= 768)
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const location = useLocation()
    useEffect(() =>
    {
        window.scrollTo(0, 0)
    }, [location])

    // Text in a Steve Jobs–inspired style, without participle phrases
    const rawParagraphs = [
        `We choose bold action. We stand on the shoulders of friends who share our mission. Mickey introduced Patrick to Robert. He visits real communities. He learns about daily challenges. He steers our product toward practical solutions.`,

        `Ben created our infrastructure. He set up emails and domains. He believed in direct democracy from the start. He fueled our progress with unwavering support. Carson shaped our strategy. He anchored our product as an intelligent channel between people and decision-makers. He charted a path that starts small and scales with every new voice.`,

        `We see execution as the bridge between vision and reality. We refuse half measures. We deliver results that matter. We build a future that honors every perspective. This is our promise.`
    ]

    // Transform paragraphs to avoid orphan words
    const paragraphs = useAvoidOrphanWords(rawParagraphs, isMobile)

    // Get styles
    const styles = buildStyles(isMobile)

    return (
        <>
            <style>{getAnimationStyles()}</style>

            <DirectDemocracyNavBar
                isMobile={isMobile}
                backgroundColor={styles.outerWrapper.backgroundColor as string}
            />

            <div style={styles.outerWrapper}>
                <div style={styles.container} className="fade-in-up">
                    <h2 style={styles.pageTitle}>Execution</h2>

                    <div style={styles.storyWrapper}>
                        {paragraphs.map((para, idx) => (
                            <p key={idx} style={styles.founderMessage}>
                                {para}
                            </p>
                        ))}
                        <p style={styles.signature}>— The Team</p>
                    </div>
                </div>
            </div>
        </>
    )
}